// IE11, polyfill hack
// https://forum.vuejs.org/t/nothing-but-blank-pages-in-internet-explorer-11/5638/11
// Need to be before Vue instance to resolve all promises
// JS
// import * as $ from 'jquery';

import { qs } from '../scripts/utils';
import '../services/i18n/i18n';
// STYLES
import './../styles/onboarding/main-login.scss';

window.$ = window.jQuery = require('jquery'); // required for bootstrap

require('bootstrap');
require('parsleyjs');

// ONB Scripts
import '../scripts/onboarding/login.js';
import '../scripts/onboarding/signup.js';

let utm_data = DATA_SIGNUP.utm_params;

// let isCookieHSPresent = false

const verify_password = function(val) {
  if (val.length < 8) {
    return false;
  } else if (val.length > 40) {
    return false;
  } else if (val.search(/\d/) == -1) {
    return false;
  } else if (val.search(/[a-z]/) == -1) {
    return false;
  } else if (val.search(/[A-Z]/) == -1) {
    return false;
  } else if (val.search(/[^\S]/) != -1) {
    return false;
  }
  return true;
};

const usernameHasValidationError = () => {
  return !!$("#username-parsley-custom-error-message .help-block .parsley-remote").length;
}

const checkPWD = (val) => {
  const input = $(val).val();
  if (verify_password(input)) {
    // alert('true');
    $(val)
      .addClass('valid')
      .removeClass('error');
    jQuery('#password-parsley-custom-error-message').hide();

    if (!usernameHasValidationError()) {
      $("#signupsubmit").prop("disabled", false);
      return;
    }
    $("#signupsubmit").prop("disabled", true);
  } else {
    // alert('false');
    $(val)
      .removeClass('valid')
      .addClass('error');
    jQuery('#password-parsley-custom-error-message').show();
    $("#signupsubmit").prop("disabled", true);
  }
  // return;
};




function submitHSdata(email, privacy, urlDyn, cookie) {
  // Create the new request 

  var xhr = new XMLHttpRequest();
  var url = 'https://api.hsforms.com/submissions/v3/integration/submit/3911908/c28c42fa-ad83-4c2b-b88a-a4cf2a149ea3'

  // Example request JSON:
  var data = {
    "fields": [
      {
        "name": "email",
        "value": email
      },
      {
        "name": "privacy",
        "value": true
      },
      {
        "name": "consenso_marketing_privacy",
        "value": privacy
      },
      {
        "name": "marketing_campaign",
        "value": `campaign: ${utm_data.utm_campaign ? utm_data.utm_campaign : null}, source: ${utm_data.utm_source ? utm_data.utm_source : null}, medium: ${utm_data.utm_medium ? utm_data.utm_medium : null}`
      }
    ],
    "context": {
      "hutk": cookie, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      "pageUri": urlDyn,
      "pageName": "ONB - Sign Up"
    },
  }

  var final_data = JSON.stringify(data)

  xhr.open('POST', url);
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  xhr.setRequestHeader('Content-Type', 'application/json');

  // xhr.onreadystatechange = function() {
  //     if(xhr.readyState == 4 && xhr.status == 200) { 
  //         alert(xhr.responseText); // Returns a 200 response if the submission is successful.
  //     } else if (xhr.readyState == 4 && xhr.status == 400){ 
  //         alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
  //     } else if (xhr.readyState == 4 && xhr.status == 403){ 
  //         alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
  //     } else if (xhr.readyState == 4 && xhr.status == 404){ 
  //         alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
  //     }
  //    }


  // Sends the request 
  if (window.location.hostname === 'my.euclidea.com') {
    xhr.send(final_data)
  }
}


// setta evento al click sulla cta del form
$("#signupsubmit").click(function() {

  // tag-manager 1
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'sign_up',
      uid: utm_data.uid ? utm_data.uid : null,
      campaign: utm_data.utm_campaign ? utm_data.utm_campaign : null,
      source: utm_data.utm_source ? utm_data.utm_source : null,
      medium: utm_data.utm_medium ? utm_data.utm_medium : null
    });
  }

  let email = $("#usernamesp").val()
  let privacy = $('#privacy').is(':checked')
  let urlDyn = window.location.href
  let cookieValue = null



  submitHSdata(email, privacy, urlDyn, cookieValue)

});



const newPWD = $('#id_new_password1');
const signupPWD = $('#passwordsp');

const signupEmail = $('#usernamesp');

signupPWD.focusout(function() {
  return checkPWD(signupPWD);
});

signupPWD.on("change", function() {
  return checkPWD(signupPWD);
});

signupPWD.on("input", function() {
  return checkPWD(signupPWD);
});

newPWD.focusout(function() {
  return checkPWD(newPWD);
});

signupEmail.focusout(function() {
  if (!!$("#passwordsp").length)
    return checkPWD(signupPWD);
});

signupEmail.on("change", function() {
  if (!!$("#passwordsp").length)
    return checkPWD(signupPWD);
});

signupEmail.on("input", function() {
  if (!!$("#passwordsp").length)
    return checkPWD(signupPWD);
});

function check_cookie_name(name) {
  let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) {
    // isCookieHSPresent = true
    cookieValue = match[2]
  }
  else {
    // isCookieHSPresent = false
    cookieValue = null
  }
}

$(document).ready(function() {

  // check cookie setting
  let coockieName = 'hubspotutk'
  check_cookie_name(coockieName)


  // tag-manager 0
  if (qs('#signupForm')) {

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'start_sign_up',
        uid: utm_data.uid ? utm_data.uid : null,
        campaign: utm_data.utm_campaign ? utm_data.utm_campaign : null,
        source: utm_data.utm_source ? utm_data.utm_source : null,
        medium: utm_data.utm_medium ? utm_data.utm_medium : null
      });
    }
  }
});
